@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --sidebar-width: 125px; /* Ajustado para melhor espaçamento */
  --header-height: 70px;
  --color-primary: #30466E;
  --color-secondary: #1A494B;
  --color-accent: #4BF1B1;
  --color-background: #f8f9fb;
  --color-text: #333333;
  --border-radius: 12px;
  --transition-speed: 0.25s;
  --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Layout Base */
html, body {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  overflow-x: hidden;
}

/* Header fixo */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  box-shadow: var(--shadow-md);
  z-index: 1100;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: var(--sidebar-width);
  height: calc(100vh - var(--header-height));
  background: var(--color-primary);
  padding: 1.0rem;
  transition: transform var(--transition-speed) ease;
  z-index: 1000;
  overflow-y: auto;
}

.sidebar.hidden {
  transform: translateX(-100%);
}

.menu-toggle {
  display: none;
}

/* Conteúdo Principal */
.main-content {
  flex: 1;
  margin-left: var(--sidebar-width);
  padding: 5rem;
  transition: margin-left var(--transition-speed) ease;
  max-width: 100%;
}

/* Tabela Responsiva */
.table-responsive {
  width: 100%;
  overflow-x: auto;
}

.table-responsive table {
  width: 100%;
  border-collapse: collapse;
}

/* Reduz tamanho da fonte e padding progressivamente */
@media (max-width: 1200px) {
  .table-responsive table {
    font-size: 14px;
  }
  .table-responsive th, .table-responsive td {
    padding: 8px;
  }
}

@media (max-width: 992px) {
  .table-responsive table {
    font-size: 12px;
  }
  .table-responsive th, .table-responsive td {
    padding: 6px;
  }
}

/* 🔥 Modo Mobile - Sidebar recolhível + Tabela empilhada */
@media (max-width: 768px) {
  .sidebar {
    position: absolute;
    transform: translateX(-100%);
    width: 200px;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .main-content {
    margin-left: 0;
    padding: 1rem;
  }

  .table-responsive thead {
    display: none;
  }

  .table-responsive tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    background: #fff;
  }

  .table-responsive tbody tr td {
    display: block;
    text-align: left;
    padding: 6px;
    border-bottom: 1px solid #eee;
  }

  .table-responsive tbody tr td::before {
    content: attr(data-label);
    font-weight: bold;
    display: block;
    color: var(--color-primary);
    margin-bottom: 4px;
  }
}

/* Responsividade para telas grandes (1025px em diante) */
@media (min-width: 1025px) {
  .sidebar {
    transform: translateX(0);
  }
  .main-content {
    margin-left: var(--sidebar-width);
  }
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in {
  animation: fadeIn 0.5s ease-out;
}

/* Scrollbar personalizada */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: var(--color-background);
}
::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #16a085;
}