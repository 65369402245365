:root {
    --color-primary: #274040;  /* Sidebar, header e footer */
    --color-secondary: #038C7F;  /* Destaque */
    --color-tertiary: #406E73;  /* Contraste */
}
  
.login-container {
    display: flex;
    height: 100vh;
}
  
/* Seção esquerda com gradiente personalizado */
.left-section {
    width: 50%;
    background: linear-gradient(135deg, var(--color-secondary), var(--color-tertiary));
    color: white;
    text-align: center;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.left-section img {
    max-width: 120px;
    margin-bottom: 20px;
}
  
.right-section {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
/* Caixa de login */
.login-box {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease;
}
  
.left-section h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
}
  
.left-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}
  
.left-section button {
    border-radius: 30px;
    padding: 12px 40px;
    font-weight: bold;
    background-color: var(--color-primary);
    border: none;
    color: white;
    transition: background-color 0.3s ease;
}
  
.left-section button:hover {
    background-color: var(--color-secondary);
}
  
/* Campos de input */
input::placeholder {
    color: #bbb;
}
  
input {
    border-radius: 10px;
    padding: 14px 18px;
    border: 2px solid var(--color-primary);
}
  
input:focus {
    border-color: var(--color-secondary);
    box-shadow: 0 0 8px rgba(3, 140, 127, 0.6);
    outline: none;
}
  
/* Botão de login */
.btn-primary  {
    background-color: var(--color-secondary);
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 10px;
    padding: 12px;
    border: none;
    transition: background-color 0.3s ease;
}
  
.btn-primary:hover {
    background-color: var(--color-tertiary);
}
  
/* Responsividade */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }
  
    .left-section, 
    .right-section {
        width: 100%;
        height: 50vh;
    }
  
    .left-section {
        padding: 30px;
    }
  
    .login-box {
        max-width: 90%;
    }
}
  
/* Animação de entrada */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
