:root {
  --color-primary: #2d3e50; /* Azul escuro moderno */
  --color-secondary: #17a589; /* Verde vibrante */
  --color-background: #f4f6f9; /* Fundo neutro claro */
}

/* Aplicar cor de fundo global */
body {
  background-color: var(--color-background);
}

/* Reset básico para consistência */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Animações para Alertas */
.alert-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.alert-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.alert-exit {
  opacity: 1;
  transform: translateY(0);
}

.alert-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

/* Cartões e Containers */
.card {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
}

.card-header {
  background: var(--color-primary);
  color: #fff;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
}

.card-body {
  padding: 1.5rem;
}

/* Estilo para Formulários */
.form-control,
.form-select {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.75rem;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-control:focus,
.form-select:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 5px rgba(45, 62, 80, 0.3);
}

/* Botões Modernos */
.btn {
  border-radius: 6px;
  font-weight: 500;
  padding: 0.5rem 1.25rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover {
  transform: translateY(-2px);
}

/* Tabela */
.table {
  font-size: 0.95rem;
}

.table thead th {
  background-color: #f7f7f7;
  border-bottom: 2px solid #dee2e6;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Estilos para os comentários */
.comments-section {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.comment-body {
  border: none;
  padding: 15px;
  border-radius: 8px;
  background: #f0f2f5;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  font-size: 0.95rem;
  color: #333;
  line-height: 1.4;
}

.comment-body p {
  margin-bottom: 0.5rem;
}

.comment-date {
  margin-top: 5px;
  font-size: 0.85rem;
  color: #888;
}

/* Detalhes do ticket */
.ticket-details p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

/* Modal customizado */
.modal-header {
  background: var(--color-primary);
  color: #fff;
  border-bottom: none;
}

.modal-title {
  font-weight: 600;
}

/* Alerts personalizados */
.alert {
  border-radius: 8px;
  font-size: 0.95rem;
  padding: 0.75rem 1.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

/* Ajustes de Responsividade */
@media (max-width: 768px) {
  .card-body {
    padding: 1rem;
  }

  .form-control,
  .form-select {
    font-size: 0.95rem;
  }
}

/* 
   Classe customizada para forçar um modal maior 
   em telas maiores (>= 992px)
*/
@media (min-width: 992px) {
  .custom-modal {
    max-width: 90% !important; /* Modal ocupa 90% da largura da tela */
    width: 90%; 
    margin: 1.75rem auto;
  }
  .custom-modal .modal-content {
    min-height: 80vh; /* Modal com altura mínima de 80% da viewport */
  }
}
